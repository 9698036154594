import { withTranslation } from "react-i18next";
import React, { Component } from "react";
class Help extends Component {
  render() {
    return (
      <div id ="akwaba-container-items" >
       <p>Menue</p>
      </div>
    );
  }
}
 
export default withTranslation()(Help);