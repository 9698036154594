import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Home from "./clientapp/pages/Home";
import Services from "./clientapp/pages/Services";
import Contact from "./clientapp/pages/messages/Contact";
import Menue from "./clientapp/pages/Menue";
import References from "./clientapp/pages/References";
import Booking from "./clientapp/pages/Booking";
import Register from "./clientapp/registerlogin/Register";
import RegisterSimple from "./clientapp/registerlogin/RegisterSimple";
import Loginscreen from "./clientapp/registerlogin/LoginScreen";
import Login from "./clientapp/registerlogin/Login";
import RetrieveMyMessages from "./clientapp/pages/messages/RetrieveMyMessages";
import MessageMyAdmin from "./clientapp/admin/MessageAdminPage";
import MarveltechSolution from "./clientapp/pages/marveltech/MarveltechSolution";
import MarveltechGaming from "./clientapp/pages/marveltech/MarveltechGaming";
import MarveltechTrade from "./clientapp/pages/marveltech/MarveltechTrade";
import MarveltechSystems from "./clientapp/pages/marveltech/MarveltechSystems";
import MarveltechConsulting from "./clientapp/pages/marveltech/MarveltechConsulting";
import MarveltechServices from "./clientapp/pages/marveltech/MarveltechServices";
import MarveltechPartners from "./clientapp/partners/MarveltechPartners";
// import MarveltechAcademy from "./clientapp/pages/marveltech/MarveltechAcademy";
import MarveltechSell from "./clientapp/pages/marveltech/tradepages/MarveltechSell";
import MarveltechOrder from "./clientapp/pages/marveltech/tradepages/MarveltechOrder";
import MarveltechGadgets from "./clientapp/pages/marveltech/tradepages/MarveltechGadgets";
import BusinessTrainings from "./clientapp/academy/Trainings/BusinessTrainings";
import SoftwareDevTrainings from "./clientapp/academy/Trainings/SoftwareDevTrainings";
import MyAdmin from "./clientapp/admin/MyAdminPage";
import Trainings from "./clientapp/academy/Trainings/Trainings";
import Apprenticeship from "./clientapp/academy/Apprenticeship/Apprenticeship";
import Logout from "./clientapp/registerlogin/Logout";
import Authservice from "./clientapp/Authentication/AuthService";
import Authservice2 from "./clientapp/Authentication/AuthService2";
import Onboardingtests from "./clientapp/academy/Apprenticeship/Onboardingtests"; 
import Partnerservice from "./clientapp/partners/Partnerservice";
import UsefullLinks from "./clientapp/admin/UsefullLinks";
import Aboutus from "./clientapp/pages/Aboutus";
import Disclaimer from "./clientapp/footer/disclaimer";
import Help from "./clientapp/footer/help";
import Followus from "./clientapp/footer/followus";
import AkwabatechMessageAdminPage from "./clientapp/admin/akwabatech_niangamee/AkwabatechMessageAdminPage";
import AmotradeciMessageAdminPage from "./clientapp/admin/amotradeci/AmotradeciMessageAdminPage";
import DakonMessageAdminPage from "./clientapp/admin/dakonproduction/DakonMessageAdminPage";
import GifterMessageAdminPage from "./clientapp/admin/gifteronline/GifterMessageAdminPage";
import MarveltechMessageAdminPage from "./clientapp/admin/marveltech/MarveltechMessageAdminPage";
import PerleMessageAdminPage from "./clientapp/admin/perledorange/PerleMessageAdminPage";
import SignupMessageAdminPage from "./clientapp/admin/signupforevents/SignupMessageAdminPage";
import TouritradeMessageAdminPage from "./clientapp/admin/touritrade/TouritradeMessageAdminPage";
class Main extends Component {
  render() {
    return (
      <div>
        <HashRouter>
          <Route exact path="/" component={Home} />
          <Route path="/home" component={Home} />
          <Route path="/services" component={Services} />
          <Route path="/menue" component={Menue} />
          <Route path="/disclaimer" component={Disclaimer} />
          <Route path="/help" component={Help} />
          <Route path="/followus" component={Followus} />
          <Route path="/reference" component={References} />
          <Route path="/booking" component={Booking} />
          <Route path="/register" component={Register} />
          <Route path="/registersimple" component={RegisterSimple} />
          <Route path="/adminpage" component={MyAdmin} />
          <Route path="/loginscreen" component={Loginscreen} />
          <Route path="/login" component={Login} />
          <Route path="/contact" component={Contact} />
          <Route path="/mymessages" component={RetrieveMyMessages} />
          <Route path="/marveltechsolution" component={MarveltechSolution} />
          <Route path="/marveltechconsulting" component={MarveltechConsulting} />
          <Route path="/marveltechgaming" component={MarveltechGaming} />
          <Route path="/marveltechtrade" component={MarveltechTrade} />
          <Route path="/marveltechsystems" component={MarveltechSystems} />
          {/* <Route path="/Marveltechacademy" component={MarveltechAcademy} /> */}
          <Route path="/marveltechservices" component={MarveltechServices} />
          <Route path="/marveltechpartners" component={MarveltechPartners} />
          <Route path="/marveltechsell" component={MarveltechSell} />
          <Route path="/marveltechorder" component={MarveltechOrder} />
          <Route path="/marveltechgadgets" component={MarveltechGadgets} />
          <Route path="/businesstrainings" component={BusinessTrainings} />
          <Route path="/softwaredevtrainings" component={SoftwareDevTrainings}/>
          <Route path="/messageadmin" component={MessageMyAdmin} />
          <Route path="/trainings" component={Trainings} />         
          <Route path="/apprenticeship" component={Apprenticeship} />
          <Route path="/onboardingtests" component={Onboardingtests} />
          <Route path="/usefulllinks" component={UsefullLinks} />          
          <Route path="/partnerservice" component={Partnerservice} />                               
          <Route path="/logout" component={Logout} /> 
          <Route path="/authservice" component={Authservice} />
          <Route path="/authservice2" component={Authservice2} /> 
          <Route path="/aboutus" component={Aboutus} /> 
          <Route path="/akwabatechmessageadmin" component={AkwabatechMessageAdminPage} />
          <Route path="/amotradecimessageadmin" component={AmotradeciMessageAdminPage} />
          <Route path="/dakonmessageadmin" component={DakonMessageAdminPage} />
          <Route path="/giftermessageadmin" component={GifterMessageAdminPage} /> 
          <Route path="/marveltechmessageadmin" component={MarveltechMessageAdminPage} />
          <Route path="/perlemessageadmin" component={PerleMessageAdminPage} />
          <Route path="/signupmessageadmin" component={SignupMessageAdminPage} />
          <Route path="/touritrademessageadmin" component={TouritradeMessageAdminPage} /> 

          <Route />                       
        </HashRouter>
      </div>
    );
  }
}

export default withTranslation()(Main);
