import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class Disclaimer extends Component {
  render() {
    const { t } = this.props;
    return (     
      <div id ="akwaba-container-items" >
         <div><h4>Disclaimer</h4></div>
       <p>{t("pages.disclaimer.content1")}</p>
       <p>{t("pages.disclaimer.content2")}</p>
       <p>{t("pages.disclaimer.content3")}</p>
       <p>{t("pages.disclaimer.content4")}</p>
       <p>{t("pages.disclaimer.content5")}</p>
       <p>{t("pages.disclaimer.content6")}</p>
       <p>{t("pages.disclaimer.content7")}</p>
       <p>{t("pages.disclaimer.content8")}</p>
       <p>{t("pages.disclaimer.content9")}</p>
       <p>{t("pages.disclaimer.content10")}</p>
       <p>{t("pages.disclaimer.content11")}</p>
      </div>
    );
  }
}
 
export default withTranslation()(Disclaimer);