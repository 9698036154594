import { withTranslation } from "react-i18next";
import React, { Component } from "react";
class Followus extends Component {
  render() {
    return (
      <div id ="akwaba-container-items" >
       <div id ="akwaba-container-items" >
         <div><h4>follows</h4></div>
       <p>Facebook: Marveltech</p>       
      </div>
      </div>
    );
  }
}
 
export default (withTranslation)(Followus);