import React, { Component } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { withTranslation } from "react-i18next";

class MarveltechPartners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="content-justscript-akwaba">
         <h3>{t("pages.partners.header")}</h3>        
        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
             <h3>Koneautoci - www.koneautoci.com</h3> 
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="content-accordion">
              <h3>{t("pages.partners.kone")}</h3>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              <h3>Touritrade — www.touritrade.com</h3>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body className="content-accordion">
              <h3>{t("pages.partners.touritrade")}</h3> 
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
           <h3>Dakon production - www.dakonproduction.com</h3> 
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body className="content-accordion">
              <h3>{t("pages.partners.dakon")}</h3> 
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
           <h3>Gifter online - www.gifteronline.com</h3> 
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body className="content-accordion">
              <h3>{t("pages.partners.gifter")}</h3>                
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    );
  }
}

export default withTranslation()(MarveltechPartners);
