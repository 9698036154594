import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
 
class Aboutus extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="akwaba-container-items-aboutus">
          <div className="aboutus-titles">
         <h1>{t("about.thecompany")}</h1>
         <p>{t("about.aboutus1")}</p>
         <p>{t("about.aboutus1-1")}</p>
         <p>{t("about.aboutus1-2")}</p>
         <p>{t("about.aboutus1-3")}</p>
         <p>{t("about.theteam1")}</p>
         <p>{t("about.theteam2")}</p>
         </div>
       
         <div className="aboutus-titles">
         <h1>{t("about.thecustomers")}</h1>
         <p>{t("about.aboutus2")}</p>
         </div>
         <div className="aboutus-titles">
         <h1>{t("about.theproducts")}</h1>
         <p>{t("about.aboutus3")}</p>
         <p>{t("about.aboutus4")}</p>
         <p>{t("about.aboutus5")}</p>
         <p>{t("about.aboutus6")}</p>
         </div>
         </div>
      </div>
    );
  }
}
 
export default withTranslation()(Aboutus);