import React, { Component } from 'react';

class MessageDataTable extends Component {
    render() {
        return (          
            <tr>
                <td>
                    {this.props.obj.Id}
                </td>
                <td>
                    {this.props.obj.cName}
                </td>
                <td>
                    {this.props.obj.cAddress}
                </td>
                <td>
                    {this.props.obj.cTelephone}
                </td>
                <td>
                    {this.props.obj.cEmail}
                </td>
                <td>
                    {this.props.obj.qCategory}
                </td>
                <td>
                    {this.props.obj.Note}
                </td>
                <td>
                    {this.props.obj.currentLoginDetails}
                </td>
                <td>
                    {this.props.obj.Date}
                </td>
                <td>
                    {this.props.obj.EnquiryAuthor}
                </td>
            </tr>
        );
    }
}

export default MessageDataTable;